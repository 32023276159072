<style scoped lang="scss">
  .login_box {
    .login_empty {
      padding-top: 20vh;
    }
    .login_iframe {
      width: 100%;
      height: 100%;
      border: 0;
      position: absolute;
      opacity: .8;
      left: 0;
      top: 0;
    }
    .login_main {
      position: absolute;
      width: 300px;
      left: 50%;
      margin-left: -180px;
      padding: 20px 30px;
      background: #fff;
      border-radius: 6px;
      .keep_password {
          /deep/ .el-form-item__content { text-align: left;}
      }
      /deep/ .el-button {
        width: 100%;
      }
      .login_title {
        margin-bottom: 10px;
        /deep/ .el-form-item__content {
          font-size: 20px;
          font-weight: bold;
        }
      }
      .el-input__icon {
        color: $c-textColor;
      }
    }
  }
  @media screen and (max-width: 320px) {
    .login_box {
      .login_main {
        width: 250px;
        margin-left: -155px;
      }
    }
  }
</style>
<style>
  body {
    background: #00ffba !important;
  }
</style>

<template>
  <div class="login_box">
    <iframe id="loginParticle" :src="'static/particle/' + loginIframe + '.html'" class="login_iframe" />
    <div class="login_empty"></div>
    <div class="login_main">
      <el-form ref="loginForm" :model="loginData">
        <el-form-item class="login_title">
          {{ appSiteName }}
        </el-form-item>
        <el-form-item>
          <el-input v-model="loginData.username" placeholder="用户名" @keyup.enter.native="login">
            <i slot="prefix" class="el-input__icon fa fa-user"></i>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="password" v-model="loginData.password" placeholder="密码" @keyup.enter.native="login">
            <i slot="prefix" class="el-input__icon fa fa-lock"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="记住密码：" class="keep_password">
          <el-switch
            active-color="#13ce66"
            inactive-color="#ddd">
          </el-switch>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login" :loading="isLoading">登 录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import skinTheme from '@/mixins/skinTheme'
export default {
  name: "login",
  mixins: [skinTheme],
  data() {
    return {
      isLoading: false,
      loginIframe: 1,
      loginParticleNum: 19, // 粒子特效页面的个数，用于随机显示特效页
      loginData: {
        tenancyName: "",
        username: "",
        password: ""
      }
    }
  },
  created() {
    this.showIframe()
  },
  mounted() {
    // 登录界面清空权限数据
    if (this.$store.getters.roles && this.$store.getters.roles.length > 0) {
      this.$store.dispatch("clearPermissions")
    }
    // 设置系统配置
    this.setSystemConfig()
    // 获取皮肤
    this.getSkinByState()
    // 监控回车
    this.enterKeyup()
  },
  methods: {
    showIframe: function() {
      const particleNum = Math.floor(Math.random() * this.loginParticleNum + 1)
      this.loginIframe = particleNum
    },
    login() {
      this.isLoading = true
      this.$store.dispatch("login", this.loginData).then(res => {
        this.isLoading = false
        this.$router.push("/")
      }).catch(e => {
        this.isLoading = false
      })
    },
    enterKey(event) {
        const code = event.keyCode
            ? event.keyCode
            : event.which
                ? event.which
                : event.charCode;
        if (code == 13) {
            this.login();
        }
    },
    enterKeyup() {
        // document.addEventListener("keyup", this.enterKey);
    }
  }
}
</script>
